import React, { useMemo, useState } from 'react'
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import { infoPopupText } from '../items'
import { Stack100, StyledAccordion, StyledAccordionSummary, TFMargin } from './TrackParams.styled'
import { IconButtonStyled } from '../InfoPopup/InfoPopup.styled'
import { Delete } from '@mui/icons-material'
import { GridJCC } from '../AddReleaseCard.styled'
import { TrackDropzone } from './TrackDropzone'
import { LabelWithInfoPopup } from './LabelWithInfoPopup'
import { SelectField } from '../SelectField/SelectField'
import releaseStore, { ReleaseGenre } from '../../../../store/ReleaseStore'


interface TrackFieldZoneProps {
    helpVisibility: boolean
    onDelete: () => void
}


export const TrackFieldZone: React.FC<TrackFieldZoneProps> = ({ helpVisibility, onDelete }) => {
    const [nameOfTrack, setNameOfTrack] = useState<string>('Трек')
    const [isISRCChecked, setIsISRCChecked] = useState(false)
    const [selectedGenre, setSelectedGenre] = useState<ReleaseGenre | null>(null)

    const handleISRCChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsISRCChecked(event.target.checked)
    }

    const handleUploadTrack = (field: string, value: any) => {
        console.log(`Поле ${field} изменено на:`, value)
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNameOfTrack(event.target.value)
    }

    const inputValue = useMemo(() => (
        <React.Fragment>
            <Typography variant="h6">
                {infoPopupText[0].text}
            </Typography>
            <Typography variant="h6">
                {infoPopupText[1].text}
            </Typography>
        </React.Fragment>
    ), [])

    const fetchGenres = async (): Promise<ReleaseGenre[]> => {
        return await releaseStore.getGenres()
    }

    const handleGenreChange = (genre: ReleaseGenre | null) => {
        setSelectedGenre(genre)
        if (genre) {
            console.log(`Выбран жанр: ${genre.name} (ID: ${genre.id})`)
        }
    }

    return (
        <StyledAccordion disableGutters>

            <Grid container direction={'row'} alignItems={'center'}>
                <Grid item xs={11}>
                    <StyledAccordionSummary>
                        <Typography variant={'body1'}>
                            {nameOfTrack}
                        </Typography>

                    </StyledAccordionSummary>
                </Grid>
                <Grid item xs={1}>
                    <IconButtonStyled onClick={onDelete}>
                        <Delete fontSize="large"/>
                    </IconButtonStyled>

                </Grid>


            </Grid>

            <Grid container spacing={1}>
                <GridJCC>

                    <TrackDropzone setSong={(song: File) => handleUploadTrack('song', song)}/>

                </GridJCC>
                <GridJCC>
                    <Stack100>
                        <LabelWithInfoPopup
                            label="Начало превью фрагмента"
                            helpVisibility={helpVisibility}
                            infoPopupValue={inputValue}
                        />
                        <TFMargin/>
                    </Stack100>
                </GridJCC>
                <GridJCC>
                    <Stack100>

                        <FormControlLabel control={<Checkbox/>}
                                          label="Ненормативная лексика"/>
                        <FormControlLabel control={<Checkbox/>}
                                          label="Инструментальная музыка"/>
                    </Stack100>
                </GridJCC>
                <GridJCC>
                    <Stack100>

                        <LabelWithInfoPopup
                            label="Название"
                            helpVisibility={helpVisibility}
                            infoPopupValue={inputValue}
                        />
                        <TFMargin value={nameOfTrack} onChange={handleChangeName} placeholder="Введите название трека"/>
                    </Stack100>

                </GridJCC>
                <GridJCC>
                    <Stack100>

                        <LabelWithInfoPopup
                            label="Исполнитель"
                            helpVisibility={helpVisibility}
                            infoPopupValue={inputValue}
                        />
                        <TFMargin placeholder="Выберите исполнителя"/> </Stack100>

                </GridJCC>
                <GridJCC>
                    <Stack100>

                        <LabelWithInfoPopup
                            label="Автор текста"
                            helpVisibility={helpVisibility}
                            infoPopupValue={inputValue}
                        />
                        <TFMargin placeholder="Введите ФИО"/> </Stack100>

                </GridJCC>
                <GridJCC>
                    <Stack100>

                        <LabelWithInfoPopup
                            label="Композитор"
                            helpVisibility={helpVisibility}
                            infoPopupValue={inputValue}
                        />
                        <TFMargin placeholder="Введите ФИО"/> </Stack100>
                </GridJCC>
                <GridJCC>
                    <Stack100>
                        <LabelWithInfoPopup
                            label="Основной жанр"
                            helpVisibility={helpVisibility}
                            infoPopupValue={inputValue}
                        />
                        <SelectField<ReleaseGenre>
                            fetchFunction={fetchGenres}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            handleInputChange={handleGenreChange}
                        />
                        <Button variant="text" disabled>
                            <Typography color="grey" textTransform="none" variant="body2">
                                Добавить дополнительный жанр
                            </Typography>
                        </Button>
                    </Stack100>
                </GridJCC>
                <GridJCC>
                    <Stack100>

                        <FormControlLabel control={<Checkbox
                            checked={isISRCChecked}
                            onChange={handleISRCChange}
                        />}
                                          label="ISRC"/>
                        <TFMargin disabled={!isISRCChecked}
                        />
                    </Stack100>

                </GridJCC>


            </Grid>
        </StyledAccordion>

    )
}