import * as React from 'react'
import { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { observer } from 'mobx-react-lite'
import authStore from '../../../store/AuthStore'
import { useNavigate } from 'react-router-dom'
import { Logo } from '../../index'


function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://vk.com/voquemusic">
                Voque Music
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

export const SignIn: React.FC = observer(() => {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const navigate = useNavigate()
    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault()
        const response = await authStore.login(username, password)
        if (response.redirectTo) {
            navigate(response.redirectTo)
        } else if (response.error) {
            console.error(response.error.message)
        }
    }

    const handleForgot = async (event: React.FormEvent) => {
        event.preventDefault()
        const response = await authStore.forgotPassword(username)
        if (response.redirectTo) {
            navigate(response.redirectTo)
        } else if (response.error) {
            console.error(response.error.message)
        }
    }

    return (
        <div className="stars signBackground ">
            <div className="twinkling signBackground "
                 style={{
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     justifyContent: 'center',
                 }}>
                <Container sx={{ backgroundColor: 'white', borderRadius: '32px' }} component="main" maxWidth="sm">

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'black', padding: '1.5%' }}>
                            <Logo className="svg-icon"/> </Avatar>
                        <Typography component="h1" variant="h3">
                            Вход в систему </Typography>
                        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}

                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Пароль"
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}

                            />
                            {/*<FormControlLabel*/}
                            {/*    control={<Checkbox value="remember" color="primary"/>}*/}
                            {/*    label="Запомнить меня"*/}
                            {/*/>*/}
                            <Button
                                type="submit"
                                disabled={authStore.isLoading}
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {authStore.isLoading ? 'Загрузка...' : 'Войти'}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link onClick={handleForgot} variant="body2" sx={{ cursor: 'pointer' }}>
                                        Забыли пароль?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="/signUp" variant="body2">
                                        {'Нет аккаунта? Регистрация'}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }}/>
                </Container>
            </div>
        </div>
    )
})