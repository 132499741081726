import { Grid, MenuItem, MenuList, Stack, styled, Typography } from '@mui/material'


export const SelectRelease = styled(MenuList)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    padding: '3%',
    //textAlign: 'center',
}))
export const SelectReleaseItem = styled(MenuItem)(({ theme, selected }) => ({
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    marginTop: '1%',
    width: '100%',
    border: 'transparent',
    borderRadius: '4px',
    textAlign: 'right',
    color: selected ? theme.palette.action.selected : theme.palette.text.primary,
    backgroundColor: selected ? 'blue' : 'white',
    '&.Mui-selected': {
        backgroundColor: 'blue',
        // Applying padding to the input element
    },
    '&:hover': {
        backgroundColor: selected ? '#352CE8 !important' : 'ligthgray',
    },
    '&.Mui-disabled': {
        backgroundColor: '#ECECEC',
        color: 'none',
    },

}))
export const SelectReleaseTypography = styled(Typography)(({ theme }) => ({
    width: '100%',
}))
SelectReleaseTypography.defaultProps = {
    fontFamily: 'Geologica400',
    textAlign: 'center',
    variant: 'h3',
    noWrap: true,
}

export const StyledMiniTitle = styled(Typography)(({ theme }) => ({}))

StyledMiniTitle.defaultProps = {
    variant: 'body2',
    color: '#8685A6',
}

export const GridJCC = styled(Grid)(({ theme }) => ({
    justifyContent: 'center',
}))
GridJCC.defaultProps = {
    container: true,
    item: true,
}

export const Stack95 = styled(Stack)(({ theme }) => ({
    flexDirection: 'column',
    width: '95%',
}))

