import React from 'react'
import { Container } from '../Container/Container'
import { AddReleaseStyled } from './AddRelease.styled'
import { Grid } from '@mui/material'
import { StyledPaper } from '../Releases/Releases.styled'
import { AddReleasesMenu } from './AddReleasesMenu/AddReleasesMenu'
import { AddReleasesCard } from './AddReleasesCard/AddReleasesCard'


export const AddReleases: React.FC = () => {
    return <Container>
        <AddReleaseStyled container spacing={1} paddingTop={2}>
            <Grid item xs={5}>
                <StyledPaper>
                    <AddReleasesMenu/>
                </StyledPaper>
            </Grid>
            <Grid item xs={7}>
                <StyledPaper>
                    <AddReleasesCard/>
                </StyledPaper>
            </Grid>
        </AddReleaseStyled>

    </Container>
}