import { IconButton, Popover, styled } from '@mui/material'


export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
    padding: '0',
}))
export const PopoverStyled = styled(Popover)(({ theme }) => ({
    '& .MuiPopover-root': {},
    '& .MuiPopover-paper': {
        borderRadius: '12px',
        padding: '1%',
        minWidth: '15%',
        maxWidth: '35%',
        minHeight: '15%',
        maxHeight: '30%',
        height: '23rem',

    },
}))
PopoverStyled.defaultProps = {
    anchorOrigin: {

        vertical: 'bottom',
        horizontal: 'center',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
}
