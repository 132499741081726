import { createTheme } from '@mui/material'


export const theme = createTheme({
    typography: {
        h1: {
            fontSize: '3rem',       // 30px
            fontFamily: 'Geologica700',
        },
        h2: {
            fontSize: '2.5rem',    // 25px
            fontFamily: 'Geologica600',

        },
        h3: {
            fontSize: '2rem',    // 20px
            fontFamily: 'Geologica600',

        },
        h4: {
            fontSize: '1.75rem',     // 17.5px
            fontFamily: 'Geologica600',
        },
        h5: {
            fontSize: '1.5rem',    // 15px
            fontFamily: 'Geologica500',

        },
        h6: {
            fontSize: '1.4rem',       // 14px
            fontFamily: 'Geologica400',
        },
        subtitle1: {
            fontSize: '1.75rem',     // 17.5px
            fontFamily: 'Geologica600',
        },
        subtitle2: {
            fontSize: '1.9rem',    // 19px
            fontFamily: 'Geologica600',
        },
        body1: {
            fontSize: '1.5rem',     // 15px
            fontFamily: 'Geologica400',
        },
        body2: {
            fontSize: '1.4rem',    // 14px
            fontFamily: 'Geologica300',

        },
        caption: {
            fontSize: '1.1rem',   // 11px
            fontFamily: 'Geologica400',
        },
        button: {
            fontSize: '2rem',    // 20px
            fontFamily: 'Geologica400',

            textTransform: 'none',

        },
        overline: {
            fontSize: '0.875rem',   // 8.75px
        },

    },
    palette: {
        mode: 'light',
        text: {
            primary: '#1E1E1E',
            secondary: '#1E1E1E',
        },
        primary: {
            main: '#1E1E1E',

            contrastText: '#fff',

        },
        secondary: {
            main: '#fff',

            contrastText: '#fff',
        },
        background: {
            default: '#F3F3F3',

        },
        action: {
            selected: '#ECECEC',
        },
        info: {
            main: '#8685A6',
            dark: '#322CE8',
            light: '#CFCEE8',
        },

    },
})


