import { Box, MenuItem, Stack, styled, Typography } from '@mui/material'
import { MenuListStyled } from '../Menu/Menu.styled'


export const ReleaseListStyled = styled(MenuListStyled)(({ theme }) => ({}))

export const ReleaseItemStyled = styled(MenuItem)(({ theme }) => ({
    border: 'transparent',
    borderRadius: '16px',
    width: '100%',
    marginBottom: '1%',
    '&.MuiMenuItem-root': {
        paddingLeft: '0',
        paddingTop: '0',
        paddingBottom: '0',

        // Applying padding to the input element
    },

}))
export const ReleaseStack = styled(Stack)(({ theme }) => ({
    width: '100%',

}))

export const TrackTitle = styled(Typography)(({ theme, selected }) => ({
    fontFamily: selected ? 'Geologica600' : 'Geologica400',
    //todo fontsize
}))
export const TrackStatus = styled(Typography)(({ theme }) => ({
    fontFamily: 'Geologica400',
    width: '100%',
    color: theme.palette.grey[500],

}))

export const TrackDate = styled(TrackStatus)(({ theme }) => ({
    textAlign: 'end',
}))

export const TrackImage = styled(Box)(({ theme }) => ({
    border: 'transparent',
    borderRadius: '8px',
    height: '3.5vw',
}))




