import React from 'react'
import { Stack } from '@mui/material'
import { StyledMiniTitle } from '../AddReleaseCard.styled'
import { InfoPopup } from '../InfoPopup/InfoPopup'


interface LabelWithInfoPopupProps {
    label: string;
    helpVisibility: boolean;
    infoPopupValue: React.ReactNode;
}


export const LabelWithInfoPopup: React.FC<LabelWithInfoPopupProps> = ({
                                                                          label,
                                                                          helpVisibility,
                                                                          infoPopupValue,
                                                                      }) => {
    return (
        <Stack direction="row" alignItems="center" spacing={0.5}>
            <StyledMiniTitle>{label}</StyledMiniTitle>
            {helpVisibility && <InfoPopup value={infoPopupValue}/>}
        </Stack>
    )
}