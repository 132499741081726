import { Grid, Paper, styled } from '@mui/material'


export const StyledReleases = styled(Grid)(({ theme }) => ({}))

export const StyledPaper = styled(Paper)(({ theme }) => ({
    //todo fix spacing
    boxShadow: 'none',
    border: 'transparent',
    borderRadius: '32px',
}))
export const StyledPaperCard = styled(StyledPaper)(({ theme }) => ({
    borderRadius: '0 32px 32px 0',
    minHeight: '100vh',
}))