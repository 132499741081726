import { Box, Button, styled } from '@mui/material'
import { StyledPaper } from '../Releases/Releases.styled'


export const StyledPlugBut = styled(Button)(({ theme }) => ({

    backgroundColor: '#E0DEFF',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: '#E0DEFF',
        boxShadow: 'none',
    },
}))

export const StyledPaperPlug = styled(StyledPaper)(({ theme }) => ({
    backgroundColor: '#F3F3F3',
}))
export const StyledBoxPlug = styled(Box)(({ theme }) => ({
    height: 'auto',

    maxWidth: '500px',

}))
StyledBoxPlug.defaultProps = {
    sx: {
        width: {
            xs: '40%',
            sm: '50%',
            md: '60%',
            xl: '70%',
        },
    },
}