import { MenuItem, MenuList, Stack, styled } from '@mui/material'


export const ReleasesStack = styled(Stack)(({ theme }) => ({
    width: '100%',
    alignItems: 'center',
    paddingTop: '1rem',
}))
export const ReleasesMenuListStyled = styled(MenuList)(({ theme }) => ({
    width: '100%',
}))

export const ReleasesMenuItemStyled = styled(MenuItem)(({ theme }) => ({
    border: 'transparent',
    borderRadius: '32px',

    marginRight: '3%',
    marginLeft: '3%',

}))

