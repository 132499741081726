import { Autocomplete } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { TFMargin } from '../TrackParams/TrackParams.styled'
import { ContributorResponse } from '../../../../store/ContributorStore'
import { ReleaseGenre } from '../../../../store/ReleaseStore'


type FetchFunction<T> = () => Promise<T[]>
type AutocompleteOption = ContributorResponse | ReleaseGenre


interface SelectFieldProps<T extends AutocompleteOption> {
    fetchFunction: FetchFunction<T>;
    getOptionLabel: (option: T) => string;
    isOptionEqualToValue: (option: T, value: T) => boolean;
    handleInputChange: (value: T | null) => void;
}


const SelectFieldBase = <T extends AutocompleteOption>({
                                                           fetchFunction,
                                                           getOptionLabel,
                                                           isOptionEqualToValue,
                                                           handleInputChange,
                                                       }: SelectFieldProps<T>) => {
    const [options, setOptions] = useState<T[] | null>(null)

    useEffect(() => {
        const getOptions = async () => {
            try {
                const response = await fetchFunction()
                if (response) {
                    setOptions(response)
                } else {
                    console.error('Получен пустой ответ')
                }
            } catch (error) {
                console.error('Ошибка при получении:', error)
            }
        }

        getOptions()
    }, [fetchFunction])

    return (
        <Autocomplete
            onChange={(_event, value) => handleInputChange(value)}
            disablePortal
            options={options || []}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={(params) => (
                <TFMargin
                    {...params}
                    variant="outlined"
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {getOptionLabel(option)}
                </li>
            )}
        />
    )
}

export const SelectField = observer(SelectFieldBase)