import { Grid, LinearProgress, styled } from '@mui/material'
import { StyledPaper } from '../Releases.styled'
import { TrackImage } from '../ReleaseList/ReleaseList.styled'


export const ReleasePaperStyled = styled(StyledPaper)(({ theme }) => ({
    backgroundColor: '#ECECEC',
    borderRadius: '16px',
    height: 'inherit',

}))

export const ReleaseImgStyled = styled(TrackImage)(({ theme }) => ({
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '12px',
}))
export const ReleaseCardContainer = styled(Grid)(({ theme }) => ({}))

export const WhiteCardReleaseStyled = styled(StyledPaper)(({ theme }) => ({
    borderRadius: '12px',

}))
export const LinearProgressStyled = styled(LinearProgress)(({ theme }) => ({
    backgroundColor: theme.palette.info.light,
    minHeight: '20%',
    maxHeight: '50%',
    width: '100%',
    '& .MuiLinearProgress-bar': {
        backgroundColor: theme.palette.info.dark,
    },
}))
