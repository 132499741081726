import { MenuProps } from './Menu/Menu'


const menuItems: MenuProps['items'] = [
    { title: 'Релизы' },
    { title: 'Шейр' },
    { title: 'Мультиссылки' },
    { title: 'Финансы' },
    { title: 'Статистика' },
    { title: 'Поддержка' },
]

export { menuItems }