import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { AddRelease, Home, Profile } from './Routes'
import { SignUp } from '../components/Authentication/SignUp/SignUp'
import { SignIn } from '../components/Authentication/SignIn/SignIn'
import React, { useEffect, useState } from 'react'
import authStore from '../store/AuthStore'
import { observer } from 'mobx-react-lite'
import ProtectedRoute from './ProtectedRoute'
import { AddReleases } from '../components/AddRelease/AddRelease'


export const Router = observer(() => {
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const checkAuth = async () => {
            const response = await authStore.checkAuthenticated()
            if (response.redirectTo) {
                navigate(response.redirectTo)
            }
            setLoading(false)
        }

        if (location.pathname !== '/signUp') {
            checkAuth()
        } else {
            setLoading(false)
        }
    }, [navigate, location.pathname])

    if (loading) {
        return null
    }

    return (
        <Routes>
            <Route path="/" element={<ProtectedRoute element={Home}/>}/>
            <Route path="/add" element={<ProtectedRoute element={AddRelease}/>}/>
            <Route path="/profile" element={<ProtectedRoute element={Profile}/>}/>
            <Route path="/signUp" element={<SignUp/>}/>
            <Route path="/signIn" element={<SignIn/>}/>
            <Route path="/edit-release/:id" element={<ProtectedRoute element={AddRelease}/>}/>
        </Routes>
    )
})
