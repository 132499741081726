import React, { useState } from 'react'
import {
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
    StyledAddTrackButton,
} from './TrackParams.styled'
import { observer } from 'mobx-react-lite'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Checkbox, Grid, Stack, Typography } from '@mui/material'
import { TrackFieldZone } from './TrackFieldZone'


export const TrackParams: React.FC = observer(() => {
    const [tracks, setTracks] = useState<number[]>([])
    const [helpVisibility, setHelpVisibility] = useState(true)

    const handleAddTrack = () => {
        setTracks(prevTracks => [...prevTracks, prevTracks.length])
    }
    const handleDeleteTrack = (trackIndex: number) => {
        setTracks(prevTracks => prevTracks.filter(index => index !== trackIndex))
    }

    return (

        <StyledAccordion defaultExpanded disableGutters>
            <Grid container direction={'row'} alignItems={'center'}>
                <Grid item xs={8}>
                    <StyledAccordionSummary expandIcon={<ExpandMoreIcon fontSize="large"/>}>
                        <Typography variant={'subtitle1'}>
                            ТРЕКИ
                        </Typography>

                    </StyledAccordionSummary>
                </Grid>
                <Grid item xs={4}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                        <Typography variant="body1" color="grey">Подсказки</Typography>
                        <Checkbox
                            onChange={() => setHelpVisibility(!helpVisibility)}
                            defaultChecked
                        />
                    </Stack>
                </Grid>


            </Grid>
            <StyledAccordionDetails>
                {tracks.map((trackIndex) => (
                    <TrackFieldZone key={trackIndex}
                                    helpVisibility={helpVisibility}
                                    onDelete={() => handleDeleteTrack(trackIndex)}
                    />
                ))}
            </StyledAccordionDetails>
            <Grid item xs={12}>
                <StyledAddTrackButton fullWidth variant="outlined" onClick={handleAddTrack}>
                    <Typography color="#352CE8" textTransform="none" variant="body2">Добавить
                        трек</Typography>
                </StyledAddTrackButton>
            </Grid>
        </StyledAccordion>

    )
})

{/*<Grid item xs={12}>*/
}
{/*    <Button fullWidth variant="outlined">*/
}
{/*        <Typography textTransform="none" fontFamily="Geologica400" variant="h3">Заполнить текст*/
}
{/*            трека</Typography>*/
}
{/*    </Button>*/
}
{/*</Grid>*/
}
{/*<Grid item xs={12}>*/
}
{/*    <Typography variant="subtitle1">ШЕЙР ТРЕКА <ArrowDropDownIcon/></Typography>*/
}
{/*    <ShareParams/>*/
}
{/*</Grid>*/
}