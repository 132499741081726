import React, { useCallback, useEffect } from 'react'
import {
    Box,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { Plus } from '../index'
import {
    AddReleaseBut,
    DialogText,
    HeaderP,
    StyledCapsDialog,
    StyledDialog,
    StyledProceedButton,
    StyledTextDialog,
} from './Header.styled'
import { ArrowDownward, Close, HelpOutline } from '@mui/icons-material'
import { first, fourth, second, third } from './index'
import { p1, p2, p3, p4, p5, p6 } from './items'

import { Link } from 'react-router-dom'
import { StyledPaper } from '../Releases/Releases.styled'
import { theme } from '../../styles/theme'
import { observer } from 'mobx-react-lite'
import releaseStore from '../../store/ReleaseStore'


export const AddReleaseDialog: React.FC = observer(() => {
    const [open, setOpen] = React.useState(false)
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [proceed, setProceed] = React.useState<boolean>(true)

    useEffect(() => {
        if (!open) {
            setProceed(false)
        }
    }, [open])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const handleCreateRelease = async () => {
        try {
            await releaseStore.createRelease()

        } catch (error) {
            console.log('Произошла ошибка создания релиза. Попробуйте позже')
        } finally {
            console.log('Релиз создан')
        }

    }
    // const submitRelease = useCallback(async () => {
    //     const releaseData: ReleaseObject = {
    //         id: 'some-id', // Замените на реальные данные
    //         type: formData.type,
    //         cover: formData.cover,
    //         title: formData.title,
    //         lable: formData.lable,
    //         release_date: value?.toDate() || new Date(),
    //         upc: formData.upc,
    //     }
    //     const response = await releaseStore.setRelease(releaseData)
    //     if (response.success) {
    //         console.log('Release successfully created:', response.data)
    //     } else {
    //         console.error('Failed to create release:', response.error)
    //     }
    // }, [formData, value])
    const handleWheel = (event: React.WheelEvent<HTMLButtonElement>): void => {
        event.preventDefault()

    }
    const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>): void => {
        if (event.button === 1) { // 1 означает среднюю кнопку мыши
            event.preventDefault()
        }

    }

    return (
        <React.Fragment>
            <AddReleaseBut variant="outlined" onClick={handleClickOpen}>
                <Plus style={{ paddingRight: '2%' }}/>
                <HeaderP textTransform="none" variant="h3">Создать релиз</HeaderP>
            </AddReleaseBut>
            <StyledDialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/*<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>*/}
                <DialogTitle variant="subtitle1" display={'flex'}
                             justifyContent={'space-between'}
                             alignItems={'center'}>
                    {'Памятка загрузки релиза'}
                    <div>
                        <IconButton
                            onClick={() => alert('info!')}
                        >
                            <HelpOutline color="info" fontSize="large"
                                // visibility={checked}
                            />
                        </IconButton>
                        <IconButton onClick={handleClose}>
                            <Close fontSize="large"/>
                        </IconButton>

                    </div>

                </DialogTitle>


                <DialogContent sx={{ paddingBottom: 0 }}>
                    <StyledPaper sx={{
                        backgroundColor: '#ECECEC',
                        padding: 0.7,
                    }}>
                        <StyledPaper>
                            <Grid container padding={1}>
                                <Grid item xs={9.5}
                                      paddingTop={2}
                                      paddingBottom={2}
                                      paddingLeft={2}>
                                    <Stack height="100%" direction="column" spacing={1}
                                           justifyContent="space-between">
                                        <StyledTextDialog>
                                            {p1}
                                        </StyledTextDialog>

                                        <StyledTextDialog>
                                            {p2}
                                        </StyledTextDialog>
                                        <DialogText variant="h4" textAlign="center">
                                            Сроки загрузки
                                        </DialogText>
                                        <StyledTextDialog>
                                            {p3}
                                        </StyledTextDialog>

                                        <StyledTextDialog>
                                            {p4}
                                        </StyledTextDialog>
                                        <StyledTextDialog>
                                            {p5}
                                        </StyledTextDialog>
                                        <DialogText variant="h4" textAlign="center">

                                            Релиз выйдет в 00:00 по МСК </DialogText>
                                        <StyledTextDialog>
                                            {p6}
                                        </StyledTextDialog>
                                    </Stack>

                                </Grid>
                                <Grid item xs={2.5}
                                      paddingTop={1}
                                      paddingBottom={2}
                                >
                                    <Stack height="100%" alignItems="center" justifyContent="center" spacing={1}
                                           textAlign="center">
                                        <Box component="img" src={first} maxWidth="40%"/>
                                        <StyledCapsDialog variant="body2">Заполняешь релиз</StyledCapsDialog>

                                        <ArrowDownward fontSize="large"/>
                                        <Box component="img" src={second} maxWidth="40%"/>
                                        <StyledCapsDialog variant="body2">Добавляешь промо-питчинг</StyledCapsDialog>

                                        <ArrowDownward fontSize="large"/>
                                        <Box component="img" src={third} maxWidth="40%"/>
                                        <StyledCapsDialog>Отправляешь на модерацию</StyledCapsDialog>

                                        <ArrowDownward fontSize="large"/>
                                        <Box component="img" src={fourth} maxWidth="40%"/>
                                        <StyledCapsDialog variant="body2">Релиз на платформах</StyledCapsDialog>

                                    </Stack>
                                </Grid>
                            </Grid>
                        </StyledPaper>
                    </StyledPaper>

                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Stack direction="row" alignItems="center">
                        <Checkbox
                            onChange={() => setProceed(!proceed)}
                        />
                        <DialogText>Согласен с публичной офертой</DialogText>
                    </Stack>
                    <StyledProceedButton component={Link} to={'/add'} variant="contained"
                                         disabled={!proceed} onMouseDown={handleCreateRelease}
                    >
                        <Typography
                            variant="body1">Продолжить</Typography>

                    </StyledProceedButton>
                </DialogActions>
            </StyledDialog>
        </React.Fragment>
    )
})