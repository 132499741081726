import React, { useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { DropzonePaperStyled, StyledDropzone } from './Dropzone.styled'
import { FileUpload } from '@mui/icons-material'
import { useDropzone } from 'react-dropzone'


interface PreviewFile extends File {
    preview: string;
}


interface DropzoneProps {
    setCover: (cover: string) => void;
}


export const Dropzone: React.FC<DropzoneProps> = ({ setCover }) => {
    const [file, setFile] = useState<PreviewFile | null>(null)
    const { acceptedFiles, getRootProps, getInputProps } =
        useDropzone({
            noKeyboard: true,
            maxFiles: 1,
            accept: {
                'image/*': [],
            },
            onDrop: acceptedFiles => {
                const selectedFile = acceptedFiles[0]
                setFile(Object.assign(selectedFile, {
                    preview: URL.createObjectURL(selectedFile),
                }))
                setCover(selectedFile.webkitRelativePath) // Устанавливаем путь файла в cover

            },
        })
    return (
        <React.Fragment>
            <DropzonePaperStyled sx={{ padding: 1, height: '91%' }}>
                <Stack direction="column" height="inherit">
                    <StyledDropzone {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        {file ? (
                            <Box
                                component="img"
                                src={file.preview}
                                sx={{
                                    width: 200,
                                    height: 200,
                                    objectFit: 'cover',
                                    borderRadius: 1,
                                }}
                                onLoad={() => {
                                    URL.revokeObjectURL(file.preview)
                                }}
                            />
                        ) : (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                            }}>
                                <FileUpload fontSize="large"/>
                                <Typography color="#352CE8" variant="body1" sx={{ userSelect: 'none' }}>
                                    Выберите файл для загрузки
                                </Typography>
                            </div>
                        )}
                    </StyledDropzone>
                </Stack>
            </DropzonePaperStyled>
        </React.Fragment>
    )

}