import { makeAutoObservable } from 'mobx'


export const TOKEN_KEY = 'auth-token'
export const REFRESH_TOKEN = 'refresh-token'

type CheckResponse = {
    authenticated: boolean;
    redirectTo?: string;
    logout?: boolean;
    error?: Error;
};

type AuthActionResponse = {
    success: boolean;
    redirectTo?: string;
    error?: Error;
    [key: string]: unknown;
};


// Типизация пользователя
interface User {
    email: string;
}


class AuthStore {
    user: User | null = null // Данные о текущем пользователе
    isLoading: boolean = false // Состояние загрузки
    isAuthenticated: boolean = false // Состояние аутентификации

    constructor() {
        makeAutoObservable(this)
    }

    // Метод для проверки аутентификации
    async checkAuthenticated(): Promise<CheckResponse> {
        try {
            const token = localStorage.getItem(TOKEN_KEY)
            if (token) {
                this.isAuthenticated = true
                console.log('аутентификация +++++')
                return { authenticated: true }
            }
            this.isAuthenticated = false
            return {
                authenticated: false,
                error: new Error('Token not found'),
                logout: true,
                redirectTo: '/signIn',
            }
        } catch (error) {
            this.isAuthenticated = false
            return {
                authenticated: false,
                error: new Error('Unexpected error occurred during check'),
                logout: true,
                redirectTo: '/signIn',
            }
        } finally {
            console.log('Проверка аутентификации')
        }
    }

    // Метод для логина
    async login(email: string, password: string): Promise<AuthActionResponse> {
        this.isLoading = true
        try {
            const response = await fetch('http://83.222.10.61:1234/api/v1/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: new URLSearchParams({
                    username: email,
                    password: password,
                }),
            })

            const data = await response.json()

            if (data.access_token) {
                localStorage.setItem(TOKEN_KEY, data.access_token)
                if (data.refresh_token) {
                    localStorage.setItem(REFRESH_TOKEN, data.refresh_token)
                }
                this.isAuthenticated = true
                return {
                    success: true,
                    redirectTo: '/',
                }
            }

            return {
                success: false,
                error: new Error('Неправильная почта или пароль'),
            }
        } catch (error) {
            this.isAuthenticated = false
            return {
                success: false,
                error: new Error('Не удалось связаться с сервером'),
            }
        } finally {
            this.isLoading = false
            console.log('Попытка авторизации завершена')
        }
    }

    // Метод для логаута
    async logout(): Promise<AuthActionResponse> {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(REFRESH_TOKEN)
        this.user = null
        this.isAuthenticated = false
        return {
            success: true,
            redirectTo: '/signIn',
        }
    }

    // Метод для регистрации
    async register(email: string, password: string): Promise<AuthActionResponse> {
        this.isLoading = true
        try {
            const response = await fetch('http://83.222.10.61:1234/api/v1/auth/register', {
                method: 'POST',
                body: JSON.stringify({ email, password }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const data = await response.json()

            if (data.id) {
                this.isAuthenticated = true
                return {
                    success: true,
                    redirectTo: '/signIn',
                }
            }

            return { success: false, error: new Error('Ошибка регистрации') }
        } catch (error) {
            console.error('Ошибка регистрации', error)
            this.isAuthenticated = false
            return { success: false, error: new Error('Ошибка сети') }
        } finally {
            this.isLoading = false
        }
    }

//todo сделать рабочим
    async forgotPassword(email: string): Promise<AuthActionResponse> {
        try {
            const response = await fetch('http://83.222.10.61:1234/api/v1/auth/forgot-password', {
                method: 'POST',
                body: JSON.stringify({ email }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            if (response.ok) {
                return {
                    success: true,
                    redirectTo: '/signUp',
                }
            } else return {
                success: false,
                error: new Error('Ошибка регистрации'),
            }
        } catch (error) {
            console.error('Ошибка пароля', error)
            return { success: false, error: new Error('Ошибка сети') }

        } finally {
            console.log('password forgot complete')
        }
    }
}


const authStore = new AuthStore()
export default authStore

//todo добавить newAccessToken func