const userDocumentsItems = {
    surname: 'Калашников',
    name: 'Вячеслав',
    thirdName: 'Сергеевич',
    birthdate: '',
    birthplace: '',
    citizenship: 'russia',
    documentId: '8020999999',
    documentDate: '5345553',
    departmentCode: '720-039',
    placeOfIssue: 'UFMSdasdsa',
    email: 'slavabogue.connect@gmail.com',
}
const userRegistrationItems = {
    postIndex: '450071',
    city: 'Уфа',
    street: 'Менделеева 197',
    apartment: '6',
}
const userRequisitesItems = {
    inn: '4823843483224',
    bankAccount: '42343242423423423',
    bankName: 'Сбербанк',
    bik: '423432424234',
    correspondentAccount: '4234324242',

}

export { userDocumentsItems, userRegistrationItems, userRequisitesItems }