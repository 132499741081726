import React, { useState } from 'react'
import { Container } from '../Container/Container'
import { UserStyledPaper } from './UserProfile.styled'
import { ComponentKey, Menu } from '../Releases/Menu/Menu'
import { userProfileItems } from './items'
import { Avatar, Button, Grid, Stack, Typography } from '@mui/material'
import { StyledPaper } from '../Releases/Releases.styled'
import { UserSettings } from './UserSettings/UserSettings'
import { ava } from '../index'
import { UserDocuments } from './UserDocuments/UserDocuments'
import { observer } from 'mobx-react-lite'
import { RenderDocument } from './RenderDocument/RenderDocument'


export const UserProfile: React.FC = observer(() => {

    const [selectedMenuId, setSelectedMenuId] = useState<ComponentKey | null>(null)

    const handleMenuClick = (id: ComponentKey) => {
        setSelectedMenuId(id)
        // Очистите выбранный ID релиза при смене меню
    }
    return <Container>
        <Grid container spacing={1} paddingTop={2}>
            <Grid item xs={2}>
                <UserStyledPaper>
                    <Menu items={userProfileItems}
                          onMenuClick={handleMenuClick}
                          selectedId={selectedMenuId}

                    />
                </UserStyledPaper>
            </Grid>

            {/* Условие для отображения ReleaseList */}
            {selectedMenuId === 0 && ( // Измените 1 на id первого элемента меню
                <Grid item container xs={7} spacing={1}>
                    <Grid item xs={7}>
                        <UserSettings/>

                    </Grid>
                    <Grid item xs={5}>
                        <StyledPaper>
                            <Stack width="100%" alignItems="center" padding={2} direction="column" spacing={1}>
                                <Avatar src={ava}/>
                                <Button variant="outlined">
                                    <Typography textTransform="none" fontFamily="Geologica400" variant="body2">Изменить
                                        фото</Typography>
                                </Button>
                            </Stack>

                        </StyledPaper>
                    </Grid>
                </Grid>

            )}
            {selectedMenuId === 1 && ( // Измените 1 на id первого элемента меню
                <Grid item xs={4.5}>

                    <p>Здесь будут реквизиты</p>
                </Grid>
            )}
            {selectedMenuId === 2 && ( // Измените 1 на id первого элемента меню
                <Grid item container spacing={1} xs={10}>
                    <Grid item xs={4}>

                        <UserDocuments/>
                    </Grid>
                    <Grid item xs={8}>
                        <RenderDocument/>
                    </Grid>
                </Grid>

            )}


        </Grid>
    </Container>
})