import { makeAutoObservable } from 'mobx'
import { TOKEN_KEY } from './AuthStore'


const API_URL = 'http://83.222.10.61:1234/api/v1'


export interface ContributorResponse {

    id: string,
    spotify_id: string,
    apple_music_id: 0,
    username: string

}


export interface GetContributorsResponse extends Array<ContributorResponse> {
}


export interface GetContributorById extends ContributorResponse {
    first_name: string,
    last_name: string,
    middle_name: string
}


class ContributorStore {

    constructor() {
        makeAutoObservable(this)
        // this.apiUrl = process.env.API_URL || 'http://localhost:8000'
    }

    async getContributorById(id: string): Promise<GetContributorById> {
        const token = localStorage.getItem(TOKEN_KEY)
        try {
            const response = await fetch(`${API_URL}/contributors/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
            const data = await response.json()
            return data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    async getContributors(): Promise<GetContributorsResponse> {
        const token = localStorage.getItem(TOKEN_KEY)
        try {
            const response = await fetch(API_URL + '/contributors/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
            const data = await response.json()
            return data

        } catch (error) {
            console.error(error)
            throw error
        } finally {
            console.log('Попытка получить авторов закончена')
        }
    }
}


const contributorStore = new ContributorStore()
export default contributorStore