import * as React from 'react'
import { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Container } from '@mui/material'
import { observer } from 'mobx-react-lite'
import authStore from '../../../store/AuthStore'
import { useNavigate } from 'react-router-dom'
import { Logo } from '../../index'


function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://vk.com/voquemusic">
                Voque Music
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

export const SignUp: React.FC = observer(() => {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const navigate = useNavigate()

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        const response = await authStore.register(username, password)
        if (response.redirectTo) {
            navigate(response.redirectTo)
        } else if (response.error) {
            console.error(response.error.message)
        }

    }

    return (
        <div className="stars signBackground ">
            <div className="twinkling signBackground " style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Container
                    sx={{
                        backgroundColor: 'white', borderRadius: '32px',

                    }}
                    maxWidth="sm"

                >


                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',

                        }}

                    >
                        <Avatar sx={{ m: 1, bgcolor: 'black', padding: '1.5%' }}>
                            <Logo/> </Avatar>
                        <Typography component="h1" variant="h3">
                            Регистрация </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        autoComplete="email"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Пароль"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Grid>
                                {/*<Grid item xs={12}>*/}
                                {/*    <FormControlLabel*/}
                                {/*        control={<Checkbox value="allowExtraEmails" color="primary"/>}*/}
                                {/*        label="I want to receive inspiration, marketing promotions and updates via email."*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={authStore.isLoading}
                            >
                                {authStore.isLoading ? 'Загрузка...' : 'Зарегистрироваться'}
                            </Button>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link href="/signIn" variant="body2">
                                        {'Уже зарегистрированы? Войдите'}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 5 }}/>
                </Container>
            </div>
        </div>
    )
})