import { MenuItem, MenuList, styled, Typography } from '@mui/material'


export const MenuListStyled = styled(MenuList)(({ theme }) => ({
    width: '100%',
}))

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
    border: 'transparent',
    borderRadius: '32px',
    fontFamily: 'Geologica600',
    // marginRight: '3%',
    // marginLeft: '3%',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
    width: '100%',

}))
export const MenuTitle = styled(Typography)(({ theme, selected }) => ({
    fontFamily: selected ? 'Geologica600' : 'Geologica400',

    //todo fontsize
}))
