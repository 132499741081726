import { observer } from 'mobx-react-lite'
import { ReleasePaperStyled, WhiteCardReleaseStyled } from '../ReleaseCard.styled'
import {
    StyledAccordion, StyledAccordionDetails,
    StyledAccordionSummary,
} from '../../../AddRelease/AddReleasesCard/TrackParams/TrackParams.styled'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AccordionDetails from '@mui/material/AccordionDetails'
import { TrackFieldZone } from '../../../AddRelease/AddReleasesCard/TrackParams/TrackFieldZone'
import trackStore, { GetTracksResponse } from '../../../../store/TrackStore'
import contributorStore from '../../../../store/ContributorStore'
import Grid2 from '@mui/material/Unstable_Grid2'


interface TracksStateProps {
    releaseId: string | null
}


const Tracks: React.FC<TracksStateProps> = ({ releaseId }) => {
    const [tracks, setTracks] = useState<GetTracksResponse>([])
    const [contributorNames, setContributorNames] = useState<{ [id: string]: string }>({})
    const handleLoadTracks = async (releaseId: string | null) => {
        if (!releaseId) return
        try {
            const data = await trackStore.getTracks(releaseId)
            setTracks(data)
        } catch (error) {
            console.log('error', error)
        }
    }
    const loadContributorName = async (id: string) => {
        if (!contributorNames[id]) {
            try {
                const contributor = await contributorStore.getContributorById(id)
                if (contributor) {
                    setContributorNames(prevState => ({ ...prevState, [id]: contributor.username }))
                }
            } catch (error) {
                console.log('Error fetching contributor:', error)
            }
        }
    }
    useEffect(() => {
        handleLoadTracks(releaseId)

    }, [releaseId])
    useEffect(() => {
        tracks.forEach(track => {
            track.contributors.forEach(contributor => {
                loadContributorName(contributor.id)
            })
        })
    }, [tracks])
    return (
        <React.Fragment>
            <StyledAccordion square disableGutters>

                <StyledAccordionSummary expandIcon={<ExpandMoreIcon fontSize="large"/>}>
                    <Typography padding={1} variant={'h4'} width="100%">
                        ТРЕКИ
                    </Typography>

                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    {tracks.map((track, index) => (
                        <ReleasePaperStyled key={track.id}>
                            <Grid2 container direction={'row'} alignItems={'center'}
                                   justifyContent={'space-between'} padding={1} marginBottom={1}>

                                <Grid2 xs={2}>
                                    <Typography>{index}</Typography>

                                </Grid2>
                                <Grid2 xs={4}>
                                    <Typography>{track.title}</Typography>

                                </Grid2>
                                <Grid2 xs={4}>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        {track.contributors.map(contributor => (
                                            <Typography
                                                key={contributor.id}>{contributorNames[contributor.id] || 'Загрузка...'}</Typography>
                                        ))}
                                    </Stack>

                                </Grid2>
                                <Grid2 xs={2}>
                                    <Typography>{track.record_year}</Typography>


                                </Grid2>

                            </Grid2>
                        </ReleasePaperStyled>
                    ))}
                </StyledAccordionDetails>

            </StyledAccordion>
        </React.Fragment>
    )
}
export const TracksComponent = observer(Tracks)