import React from 'react'
import { UserSettingsStyled } from './UserSettings.styled'
import { Button, Grid, Typography } from '@mui/material'
import { StyledMiniTitle } from '../../AddRelease/AddReleasesCard/AddReleaseCard.styled'
import { TFMargin } from '../../AddRelease/AddReleasesCard/TrackParams/TrackParams.styled'
import { userFieldItems } from './items'


export const UserSettings: React.FC = () => {
    return (
        <UserSettingsStyled>
            <Grid container padding={2}>

                <Grid item xs={12}>
                    <Typography variant="subtitle1">ИНФОРМАЦИЯ ПРОФИЛЯ</Typography>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Имя</StyledMiniTitle>
                    <TFMargin defaultValue={userFieldItems.name}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Фамилия</StyledMiniTitle>
                    <TFMargin defaultValue={userFieldItems.surname}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Отчество</StyledMiniTitle>
                    <TFMargin defaultValue={userFieldItems.thirdName}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Псевдоним</StyledMiniTitle>
                    <TFMargin defaultValue={userFieldItems.nickname}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Номер телефона</StyledMiniTitle>
                    <TFMargin defaultValue={userFieldItems.phone}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Email</StyledMiniTitle>
                    <TFMargin defaultValue={userFieldItems.email}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="outlined">
                        <Typography textTransform="none" variant="body1">Сохранить
                            изменения</Typography>
                    </Button>
                </Grid>
            </Grid>
        </UserSettingsStyled>
    )
}