import { Button, Grid, Stack } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import {
    ReleaseItemStyled,
    ReleaseListStyled,
    ReleaseStack,
    TrackDate,
    TrackImage,
    TrackStatus,
    TrackTitle,
} from './ReleaseList.styled'
import { ReleaseKey } from '../Releases'
import { observer } from 'mobx-react-lite'
import releaseStore, { ReleaseResponseItems } from '../../../store/ReleaseStore'
import { Delete, DeleteForever } from '@mui/icons-material'
import { IconButtonStyled } from '../../AddRelease/AddReleasesCard/InfoPopup/InfoPopup.styled'
import { statusLabels } from '../ReleaseCard/ReleaseCard'


interface ReleaseListProps {
    selectedId: ReleaseKey | null;
    setSelectedId: (id: ReleaseKey | null) => void;  // Теперь можно передавать null
    releases: ReleaseResponseItems | null;
    setReleases: (releases: ReleaseResponseItems | null) => void;  // Для обновления списка после удаления
    loading: boolean;
    error: string | null;
}


export const ReleaseList: React.FC<ReleaseListProps> = observer(({
                                                                     selectedId,
                                                                     setSelectedId,
                                                                     releases,
                                                                     setReleases,
                                                                     loading,
                                                                     error,
                                                                 }: ReleaseListProps) => {

    const itemRefs = useRef<(HTMLDivElement | null)[]>([])  // Ссылки на элементы списка

    if (loading) return <div>Загрузка...</div>
    if (error) return <div>{error}</div>

    const handleItemClick = (id: ReleaseKey, index: number) => {
        setSelectedId(id)
        itemRefs.current[index]?.focus()  // Устанавливаем фокус на элемент списка
    }

    const handleDeleteRelease = async (id: ReleaseKey) => {
        await releaseStore.deleteRelease(id)

        const updatedReleases = releases?.filter(release => release.id !== id) || null
        setReleases(updatedReleases)

        if (id === selectedId) {
            setSelectedId(null)
            if (itemRefs.current[0]) {
                itemRefs.current[0].blur()  // Переводим фокус с первого элемента списка
            }
        }
    }

    return (
        <ReleaseListStyled>
            {releases ? (
                releases.map((item, index) => (
                    <ReleaseItemStyled
                        key={item.id}
                        tabIndex={0}
                        ref={(el: HTMLDivElement | null) => (itemRefs.current[index] = el)}  // Присваиваем реф каждому элементу
                        onClick={() => handleItemClick(item.id, index)}
                        selected={item.id === selectedId}
                    >
                        <ReleaseStack alignItems="center" direction="row" spacing={1}>
                            <TrackImage component="img" src={item.cover}/>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TrackTitle selected={item.id === selectedId} variant="h4">
                                        {item.title}
                                    </TrackTitle>


                                </Grid>
                                <Grid item xs={6}>
                                    <TrackStatus noWrap variant="body1">{statusLabels[item.status]}</TrackStatus>
                                </Grid>
                                <Grid item xs={6}>
                                    <TrackDate noWrap variant="body1">
                                        {item.release_date ? item.release_date.toString().slice(0, 10) : null}
                                    </TrackDate>

                                </Grid>
                            </Grid>


                        </ReleaseStack>
                    </ReleaseItemStyled>
                ))
            ) : (
                null
            )}
        </ReleaseListStyled>
    )
})
