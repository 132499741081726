const selectReleaseItems = [
    {
        title: 'Сингл',
        price: '999 ₽',
        type: 'SINGLE',
    },
    {
        title: 'Альбом',
        price: '1499 ₽',
        type: 'ALBUM',
    },
    {
        title: 'EP',
        price: '999 ₽',
        type: 'EP',
    },
    { title: 'Клип' },
    { title: 'Звук TikTok' },
    { title: 'Подкаст' },

]
const genresItems = [
    { title: 'Экспериментальная музыка' },
    { title: '' },
    { title: '' },
    { title: '' },
    { title: '' },
    { title: '' },
    { title: '' },
    { title: '' },
]

const infoPopupText = [
    {
        text: 'В то время как ℗ уведомления уникальны для звукозаписей, уведомление' +
            '                об' +
            '                авторских правах © ("C" - Copyright) используется для обозначения' +
            '                авторских' +
            '                прав в ряде произведений, отличных от звукозаписей.',
    },
    {
        text: 'В случае музыки уведомление © обычно используется в отношении' +
            '                авторских' +
            '                прав' +
            '                на обложки, иллюстрации и другие печатные материалы или в отношении' +
            '                произведений искусства, отображаемых на веб-сайтах.',
    },
]
export { genresItems, selectReleaseItems, infoPopupText }