import { Button, Dialog, styled, Typography } from '@mui/material'
import { Link } from 'react-router-dom'


export const HeaderStyled = styled('div')(({ theme }) => ({
    height: '10rem',
    //backgroundRepeat: 'no-repeat',
    //backgroundImage: `url(${bgc})`,
    //backgroundPosition: 'center',
    // backgroundColor: 'black',
    // justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 0 32px 32px',
    userSelect: 'none',
}))
export const HeaderLogoText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontFamily: 'IBM600',
    paddingLeft: '0.5rem',

}))

export const HeaderP = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontFamily: 'Geologica500',
}))
export const AddReleaseBut = styled(Button)(({ theme }) => ({

    borderRadius: '3rem',
    borderColor: 'white',

}))

export const DialogText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Geologica400',

}))
export const StyledLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
}))

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '32px',
        // width: '15rem',
        // maxWidth: '60%',
        // minWidth: '25%',
        maxHeight: '99%',
        // minHeight: '40%',
    },

}))

export const StyledCapsDialog = styled(DialogText)(({ theme }) => ({
    fontFamily: 'Geologica500',
    fontSize: '1.25rem',
}))
export const StyledTextDialog = styled(DialogText)(({ theme }) => ({}))
StyledTextDialog.defaultProps = {
    fontFamily: 'Geologica400',
    fontSize: '1.4rem',
}
export const StyledProceedButton = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: theme.palette.info.dark,
    '& .Mui-disabled': {},
    borderRadius: '32px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: theme.palette.info.dark,
    },
}))