import React from 'react'
import { Header } from '../components/Header/Header'
import { Releases } from '../components/Releases/Releases'
import { AddReleases } from '../components/AddRelease/AddRelease'
import { UserProfile } from '../components/UserProfile/UserProfile'


export const Home = () => (
    <React.Fragment>
        <Header/>
        <Releases/>
    </React.Fragment>

)
export const AddRelease = () => (
    <React.Fragment>
        <Header/>
        <AddReleases/>
    </React.Fragment>
)

export const Profile = () => (
    <React.Fragment>
        <Header/>
        <UserProfile/>
    </React.Fragment>
)

