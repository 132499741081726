import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { StyledDropzone } from '../Dropzone/Dropzone.styled'
import { useDropzone } from 'react-dropzone'
import { StyledPaper } from '../../../Releases/Releases.styled'
import { Stack100 } from './TrackParams.styled'


interface DropzoneProps {
    setSong: (song: File) => void;
}


export const TrackDropzone: React.FC<DropzoneProps> = ({ setSong }) => {
    const [fileName, setFileName] = useState<string | null>(null)
    const { getRootProps, getInputProps } = useDropzone({
        noKeyboard: true,
        maxFiles: 1,
        accept: {
            'audio/*': ['.mp3', '.wav', '.ogg', '.flac'],
        },
        onDrop: acceptedFiles => {
            const selectedFile = acceptedFiles[0]
            setFileName(selectedFile.name)
            setSong(selectedFile) // Передаем сам файл, а не путь
        },
    })

    return (
        <StyledPaper sx={{
            border: 'solid 1px gray',
            width: '100%',
        }}>
            <Stack100 direction="column" height="inherit" padding={1}>
                <StyledDropzone {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%',
                    }}>
                        <Typography color="#352CE8" variant="body1" sx={{ userSelect: 'none' }}>
                            {fileName ? `Выбран файл: ${fileName}` : 'Выберите музыкальный файл для загрузки'}
                        </Typography>
                    </div>
                </StyledDropzone>
            </Stack100>
        </StyledPaper>
    )
}