import React from 'react'
import { UserDocumentsStyled } from './UserDocuments.styled'
import { Grid, Typography } from '@mui/material'
import { StyledMiniTitle } from '../../AddRelease/AddReleasesCard/AddReleaseCard.styled'
import { TFMargin } from '../../AddRelease/AddReleasesCard/TrackParams/TrackParams.styled'
import { userDocumentsItems, userRegistrationItems, userRequisitesItems } from './items'


export const UserDocuments: React.FC = () => {
    return (
        <UserDocumentsStyled>
            <Grid container padding={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">ПАСПОРТНЫЕ ДАННЫЕ</Typography>
                </Grid>

                <Grid item xs={12}>
                    <StyledMiniTitle>Фамилия</StyledMiniTitle>
                    <TFMargin defaultValue={userDocumentsItems.surname}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Имя</StyledMiniTitle>
                    <TFMargin defaultValue={userDocumentsItems.name}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Отчество</StyledMiniTitle>
                    <TFMargin defaultValue={userDocumentsItems.thirdName}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Дата рождения</StyledMiniTitle>
                    <TFMargin defaultValue={userDocumentsItems.birthdate}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Место рождения</StyledMiniTitle>
                    <TFMargin defaultValue={userDocumentsItems.birthplace}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Гражданство</StyledMiniTitle>
                    <TFMargin defaultValue={userDocumentsItems.citizenship}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Серия и номер паспорта</StyledMiniTitle>
                    <TFMargin defaultValue={userDocumentsItems.documentId}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Дата выдачи</StyledMiniTitle>
                    <TFMargin defaultValue={userDocumentsItems.documentDate}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Код подразделения</StyledMiniTitle>
                    <TFMargin defaultValue={userDocumentsItems.departmentCode}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Кем выдан</StyledMiniTitle>
                    <TFMargin defaultValue={userDocumentsItems.placeOfIssue}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Email</StyledMiniTitle>
                    <TFMargin defaultValue={userDocumentsItems.email}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">АДРЕС РЕГИСТРАЦИИ</Typography>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Индекс</StyledMiniTitle>
                    <TFMargin defaultValue={userRegistrationItems.postIndex}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Город</StyledMiniTitle>
                    <TFMargin defaultValue={userRegistrationItems.city}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Улица и номер дома</StyledMiniTitle>
                    <TFMargin defaultValue={userRegistrationItems.street}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Квартира</StyledMiniTitle>
                    <TFMargin defaultValue={userRegistrationItems.apartment}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">РЕКВИЗИТЫ</Typography>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>ИНН физического лица</StyledMiniTitle>
                    <TFMargin defaultValue={userRequisitesItems.inn}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Счет в банке</StyledMiniTitle>
                    <TFMargin defaultValue={userRequisitesItems.bankAccount}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Название банка</StyledMiniTitle>
                    <TFMargin defaultValue={userRequisitesItems.bankName}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>БИК</StyledMiniTitle>
                    <TFMargin defaultValue={userRequisitesItems.bik}
                              variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <StyledMiniTitle>Корреспондентский счет</StyledMiniTitle>
                    <TFMargin defaultValue={userRequisitesItems.correspondentAccount}
                              variant="outlined"/>
                </Grid>
            </Grid>

        </UserDocumentsStyled>
    )
}