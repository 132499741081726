import React from 'react'
import { MenuItemStyled, MenuListStyled, MenuTitle } from './Menu.styled'
import { Grid } from '@mui/material'


export type ComponentKey = number


export interface MenuProps {
    items: {
        title: string
    }[]
    onMenuClick: (id: ComponentKey) => void; // Изменено на ComponentKey
    selectedId: ComponentKey | null; // Используем ComponentKey
    releaseCount?: number;
}


export const Menu: React.FC<MenuProps> = ({ items, onMenuClick, selectedId, releaseCount }) => {

    return (
        <Grid container padding={2}>
            <MenuListStyled key={selectedId}>
                {items.map((item, itemIndex) => (
                    <MenuItemStyled
                        key={itemIndex}
                        onClick={() => onMenuClick(itemIndex as ComponentKey)}
                        selected={itemIndex === selectedId}
                    >
                        <MenuTitle selected={itemIndex === selectedId} noWrap variant="h5">
                            {item.title}
                        </MenuTitle>
                        {(releaseCount && item.title === 'Релизы') ?
                            <MenuTitle selected={itemIndex === selectedId} noWrap variant="h5">
                                {releaseCount}
                            </MenuTitle> :
                            (null)

                        }

                    </MenuItemStyled>
                ))}
            </MenuListStyled>
        </Grid>
    )
}