import { makeAutoObservable } from 'mobx'
import authStore, { TOKEN_KEY } from './AuthStore'


const API_URL = 'http://83.222.10.61:1234/api/v1'


interface ValidationError {
    loc: string[];
    msg: string;
    type: string;
}


interface ErrorResponse {
    detail: ValidationError[];
}


class CustomValidationError extends Error {
    public detail: ValidationError[]

    constructor(detail: ValidationError[]) {
        super('Ошибка валидации данных')
        this.name = 'CustomValidationError'
        this.detail = detail
    }
}


export type ReleaseType = 'EP' | 'SINGLE' | 'ALBUM'
export type ReleaseStatus = 'DRAFT' | 'ON_MODERATION' | 'CONFIRMED' | 'UPLOADED' | 'DELETED'
type GenreCategory = 'BASS_MUSIC' | 'DANCE_ELECTRONIC' | 'GLOBAL' | 'HH_RNB' | 'LIVE_SOUNDS' | 'POP' | 'ROCK'
export type Role =
    'MAIN_ARTIST'
    | 'CO_ARTIST'
    | 'FEAT_ARTIST'
    | 'PRODUCER'
    | 'SONGWRITER'
    | 'REMIXER'
    | 'BACKING_VOCALIST'
    | 'MUSIC_DIRECTOR'
    | 'SOUND_ENGINEER'
    | 'DJ'
    | 'COMPOSER'
    | 'ARRANGER'
    | 'PARTICIPANT'


export interface releaseGenreObject {
    genre: string,
    is_main: boolean
}


export interface ReleaseContributorObject {
    id: string,
    role: Role

}


export interface SetReleaseObject {
    type: ReleaseType,
    genres: releaseGenreObject[],
    contributors: ReleaseContributorObject[]
    label: string,
    pline: string,
    cline: string,
    title: string,
    version?: string,
    release_date: string,
    upc?: string,
}


export interface ReleaseGenre {
    id: string;
    parent_id?: string
    name: string
    genreCategory: GenreCategory
}


export interface ReleaseGenresResponse extends Array<ReleaseGenre> {
}


interface setReleaseResponse {

    detail?: [
        {
            loc: [
                'string',
                0
            ],
            msg: string,
            type: string
        }
    ]

}


interface createReleaseResponse {
    'id': 'string'
}


export interface ReleaseResponseItem {
    id: string,
    type: ReleaseType,
    cover: string,
    title: string,
    version: string,
    release_date: Date,
    upc: number,
    status: ReleaseStatus,
    promo_description: string
}


export interface ReleaseResponseItems extends Array<ReleaseResponseItem> {
}


class ReleaseStore {
    constructor() {
        makeAutoObservable(this)
    }

    async getReleases(): Promise<ReleaseResponseItems> {
        const token = localStorage.getItem(TOKEN_KEY)
        try {
            const response = await fetch(API_URL + '/releases/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
            //todo change here
            if (response.status >= 398 && response.status < 405) {
                authStore.logout()
            }
            const data = await response.json()
            return data
        } catch (error) {
            console.log(error)
            throw error

        } finally {
            console.log('Попытка получения релизов завершена')
        }
    }

    async createRelease(): Promise<createReleaseResponse> {
        const token = localStorage.getItem(TOKEN_KEY)
        try {
            const response = await fetch(API_URL + '/releases/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
            const data = await response.json()
            localStorage.setItem('releaseId', data.id)
            return {
                id: data.id,
            }
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            console.log('Попытка создания релиза завершена')
        }
    }

    async updateRelease(releaseData: SetReleaseObject, releaseID: string): Promise<any | ValidationError> {
        const token = localStorage.getItem(TOKEN_KEY)

        try {
            const response = await fetch(`${API_URL}/releases/${releaseID}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(releaseData),
            })
            if (!response.ok) {
                const errorData = await response.json()

                if (response.status === 422) {
                    // Создаем объект с требуемой структурой для ошибки валидации
                    const errorDetail: ValidationError = {
                        loc: ['string', '0'],
                        msg: errorData.detail?.[0]?.msg || 'Ошибка валидации данных',
                        type: 'string',
                    }

                    // Создаем пользовательскую ошибку с нужным форматом данных
                    throw new CustomValidationError([errorDetail])
                } else {
                    // Обработка других ошибок
                    throw new Error(errorData.detail || 'Ошибка при обновлении релиза')
                }
            }

            const data = await response.json()
            console.log(data)
        } catch (error) {
            console.error('Ошибка при обновлении релиза:', error)

            // Проверяем, является ли ошибка пользовательской ошибкой валидации
            if (error instanceof CustomValidationError) {
                console.error('Детали ошибки:', error.detail[0])
            }
        } finally {
            console.log('Попытка обновления релиза завершена')
        }
    }

    async getGenres(): Promise<ReleaseGenresResponse> {
        const token = localStorage.getItem(TOKEN_KEY)
        try {
            const response = await fetch(API_URL + '/releases/genres/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
            const data = await response.json()
            return data
        } catch (error) {
            console.log(error)
            throw error

        } finally {

        }
    }

    async deleteRelease(id: string): Promise<void> {
        const token = localStorage.getItem(TOKEN_KEY)
        try {
            await fetch(API_URL + '/releases/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
        } catch (error) {
            console.log(error)
            throw error

        } finally {

        }
    }
}


const releaseStore = new ReleaseStore()
export default releaseStore