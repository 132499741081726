import { createGlobalStyle } from 'styled-components'
import IBMPlexSansSemiBold from './fonts/static/IBM/IBMPlexSans-SemiBold.ttf'
import GeologicaBold from './fonts/static/Geologica/Geologica-Bold.ttf'
import GeologicaSemibold from './fonts/static/Geologica/Geologica-SemiBold.ttf'
import GeologicaMedium from './fonts/static/Geologica/Geologica-Medium.ttf'
import GeologicaRegular from './fonts/static/Geologica/Geologica-Regular.ttf'
import GeologicaLight from './fonts/static/Geologica/Geologica-Light.ttf'
import { theme } from './theme'
import stars from './starrySky/stars.png'
import twinkling from './starrySky/twinkling.png'
import clouds from './starrySky/clouds.png'


export const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'IBM600';
        src: url(${IBMPlexSansSemiBold}) format('truetype');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'Geologica700';
        src: url(${GeologicaBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'Geologica600';
        src: url(${GeologicaSemibold}) format('truetype');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'Geologica500';
        src: url(${GeologicaMedium}) format('truetype');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Geologica400';
        src: url(${GeologicaRegular}) format('truetype');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'Geologica300';
        src: url(${GeologicaLight}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }

    html {
        font-size: 62.5%; /* 10px при базовом размере шрифта 16px */
    }


    @media (max-width: 1200px) {
        html {
            font-size: 56.25%; /* 9px */
        }


    }

    @media (max-width: 1030px) {
        .hideWhen980 {
            display: none;
        }
    }

    @media (max-width: 768px) {
        html {
            font-size: 50%; /* 8px */
        }
    }

    @media (max-width: 480px) {
        html {
            font-size: 43.75%; /* 7px */
        }
    }


    @media (max-width: 768px) {

    }

    @media (max-width: 480px) {

    }


    h1 {
        font-size: 2.5rem;
        white-space: nowrap;
    }

    h2 {
        font-size: 1.8rem;
    }

    p {
        font-size: 1.5rem;
    }


    body {
        background: ${theme.palette.background.default};
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    /*.noLineHeight {
        line-height: 0;
    }*/

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
    }

    ::-webkit-scrollbar {
        display: none;
    }

    // Hide scrollbar for IE, Edge and Firefox
    html {
        -ms-overflow-style: none; // IE and Edge
        scrollbar-width: none; // Firefox
    }

    // Ensure elements still scroll
    body {
        -webkit-overflow-scrolling: touch;
    }


    @keyframes move-twink-back {
        from {
            background-position: 0 0;
        }
        to {
            background-position: -10000px 5000px;
        }
    }

    @keyframes move-clouds-back {
        from {
            background-position: 0 0;
        }
        to {
            background-position: 10000px 0;
        }
    }


    .signBackground {

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: block;
    
    }

    .stars {
        background: #000 url(${stars}) repeat top center;
        z-index: 0;
    }

    .twinkling {
        background: transparent url(${twinkling}) repeat top center;
        z-index: 1;
        animation: move-twink-back 200s linear infinite;
    }

    .clouds {
        background: transparent url(${clouds}) repeat top center;
        z-index: 2;
        opacity: .4;
        animation: move-clouds-back 200s linear infinite;
    }
`