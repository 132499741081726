import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { Container } from '../Container/Container'
import { ComponentKey, Menu } from './Menu/Menu'
import { ReleaseList } from './ReleaseList/ReleaseList'
import { ReleaseCard } from './ReleaseCard/ReleaseCard'
import { StyledPaper } from './Releases.styled'
import { menuItems } from './items'
import releaseStore, { ReleaseResponseItems } from '../../store/ReleaseStore'
import { observer } from 'mobx-react-lite'
import { Plug } from '../Plug/Plug'
import { useNavigate } from 'react-router-dom'


export type ReleaseKey = string;
const preloadImage = (src: string) => {
    const img = new Image()
    img.src = src
}

export const Releases: React.FC = observer(() => {
    const [selectedMenuId, setSelectedMenuId] = useState<ComponentKey | null>(null)
    const [selectedReleaseId, setSelectedReleaseId] = useState<ReleaseKey | null>(null)
    const [releases, setReleases] = useState<ReleaseResponseItems | null>(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        const fetchReleases = async () => {
            try {
                const response: ReleaseResponseItems = await releaseStore.getReleases()
                setReleases(response)
            } catch (err) {
                setError('Ошибка')
            } finally {
                setLoading(false)
            }
        }

        fetchReleases()
    }, [])

    useEffect(() => {
        releases?.forEach(release => {
            if (release.cover) {
                preloadImage(release.cover)
            }
        })
    }, [releases])

    const handleCloseReleaseCard = () => {
        setSelectedReleaseId(null) // Скрывает ReleaseCard
    }
    const handleMenuClick = (id: ComponentKey) => {
        setSelectedMenuId(id)
        setSelectedReleaseId(null) // Сброс выбранного релиза при смене меню
    }
    // const handleEditRelease = () => {
    //     if (selectedReleaseId) {
    //         navigate(`/edit-release/${selectedReleaseId}`)
    //
    //     }
    //
    // }

    const handleDeleteRelease = async (id: ReleaseKey) => {
        try {
            await releaseStore.deleteRelease(id)

            const updatedReleases = releases?.filter(release => release.id !== id) || []
            setReleases(updatedReleases)

            if (selectedReleaseId === id) {
                setSelectedReleaseId(null)
            }
        } catch (error) {
            console.error('Ошибка при удалении релиза:', error)
        }
    }

    const renderContent = () => {
        if (selectedMenuId === 0) {
            return (
                <Grid container padding={2}>
                    <Grid item xs={selectedReleaseId !== null ? 3.33 : 12}>
                        <ReleaseList
                            selectedId={selectedReleaseId}
                            setSelectedId={setSelectedReleaseId}
                            releases={releases}
                            setReleases={setReleases} // Для обновления списка в дочернем компоненте
                            loading={loading}
                            error={error}
                        />
                    </Grid>
                    {selectedReleaseId !== null && (
                        <Grid item xs={8.67} paddingLeft={1}>
                            <ReleaseCard
                                releases={releases}
                                initialSelectedId={selectedReleaseId}
                                onDeleteRelease={handleDeleteRelease}
                                onCloseRelease={handleCloseReleaseCard}
                                // onEditRelease={handleEditRelease}
                            />
                        </Grid>
                    )}
                </Grid>
            )
        }

        return (
            <Grid item xs={12} padding={3}>
                <Plug/>
            </Grid>
        )
    }

    return (
        <Container>
            <Grid container spacing={1} paddingTop={2}>
                <Grid item xs={1.8}>
                    <StyledPaper>
                        <Menu
                            releaseCount={releases?.length}
                            items={menuItems}
                            onMenuClick={handleMenuClick}
                            selectedId={selectedMenuId}
                        />
                    </StyledPaper>
                </Grid>

                <Grid item xs={selectedReleaseId !== null ? 10.2 : (selectedMenuId !== 0 ? 4 : 3)}>
                    <StyledPaper>
                        {selectedMenuId !== null && renderContent()}
                    </StyledPaper>
                </Grid>
            </Grid>
        </Container>
    )
})
