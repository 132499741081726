import { styled } from '@mui/material'
import { StyledPaper } from '../../../Releases/Releases.styled'


export const StyledDropzone = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    cursor: 'pointer',
}))
export const DropzonePaperStyled = styled(StyledPaper)(({ theme }) => ({
    backgroundColor: 'none',
    borderColor: '#ECECEC',
    borderRadius: '16px',
    borderStyle: 'dashed',
    height: 'inherit',

}))