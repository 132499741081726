import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { StyledPaper } from '../../Releases/Releases.styled'
import { Button, Checkbox, Grid, Stack, Typography } from '@mui/material'
import {
    GridJCC,
    SelectRelease,
    SelectReleaseItem,
    SelectReleaseTypography,
    StyledMiniTitle,
} from './AddReleaseCard.styled'
import { ReleasePaperStyled } from '../../Releases/ReleaseCard/ReleaseCard.styled'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { TrackParams } from './TrackParams/TrackParams'
import { TFMargin } from './TrackParams/TrackParams.styled'
import { infoPopupText, selectReleaseItems } from './items'
import { observer } from 'mobx-react-lite'
import { ComponentKey } from '../../Releases/Menu/Menu'
import { InfoPopup } from './InfoPopup/InfoPopup'
import { SelectField } from './SelectField/SelectField'
import { Dropzone } from './Dropzone/Dropzone'
import releaseStore, {
    ReleaseType,
    releaseGenreObject,
    ReleaseContributorObject, ReleaseGenresResponse, Role, ReleaseGenre,
} from '../../../store/ReleaseStore'
import contributorStore, { ContributorResponse, GetContributorsResponse } from '../../../store/ContributorStore'


interface FormDataType {
    type: ReleaseType;
    genres: releaseGenreObject[]
    contributors: ReleaseContributorObject[]
    label: string;
    pline: string,
    cline: string,
    title: string,
    version?: string,
    release_date: string,
    upc?: number,

}


const allowedMinDate = dayjs().add(1, 'day')

export const AddReleasesCard: React.FC = observer(() => {
    const [selectedMenuId, setSelectedMenuId] = useState<ComponentKey | null>(null)
    const [helpVisibility, setHelpVisibility] = useState(true)
    const [formData, setFormData] = useState<FormDataType>({
        type: 'EP',
        genres: [],
        contributors: [],
        label: 'Voque',
        pline: 'Some Pline',
        cline: 'Some Cline',
        title: '',
        version: 'Deluxe Edition',
        release_date: allowedMinDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        // upc: '0',
    })

    const inputValue = useMemo(() => (
        <React.Fragment>
            <Typography variant="h6">
                {infoPopupText[0].text}
            </Typography>
            <Typography variant="h6">
                {infoPopupText[1].text}
            </Typography>
        </React.Fragment>
    ), [])

    const handleTypeClick = useCallback((id: ComponentKey) => {
        setSelectedMenuId(id)
        const selectedType = selectReleaseItems[id]?.type as ReleaseType
        if (selectedType) {
            setFormData(prev => ({ ...prev, type: selectedType }))
        }
    }, [])

    const handleChangeCover = useCallback((field: string, value: any) => {
        setFormData(prev => ({ ...prev, [field]: value }))
    }, [])

    const handleInputChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value
        setFormData(prev => ({
            ...prev,
            [field]: newValue,
        }))
    }

    useEffect(() => {
        console.log('formData обновлен:', formData)
    }, [formData])

    const handleDateChange = (field: string) => (date: Dayjs | null) => {
        const newValue = date
            ? date.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            : null
        setFormData(prev => {
            const newFormData = {
                ...prev,
                [field]: newValue,
            }
            return newFormData
        })
    }

    const submitRelease = async () => {
        const releaseId = localStorage.getItem('releaseId')
        if (!releaseId) {
            console.error('No release ID found in localStorage')
            return
        }

        const releaseData = {
            type: formData.type,
            genres: formData.genres,
            contributors: formData.contributors,
            label: formData.label,
            pline: formData.pline,
            cline: formData.cline,
            title: formData.title,
            version: formData.version,
            release_date: formData.release_date,
            // upc: formData.upc,
        }

        try {
            const response = await releaseStore.updateRelease(releaseData, releaseId)

            if (response && typeof response === 'object') {
                console.log('Success response:', JSON.stringify(response))
            } else {
                console.warn('Unexpected response type:', typeof response)
            }
        } catch (error) {
            console.error('Error updating release:', error)
            // @ts-ignore
            if (error.response) {
                // @ts-ignore
                console.error('Status:', error.response.status)
                // @ts-ignore
                console.error('Data:', error.response.data)
            }
        }
    }
    const handleContributorChange = useCallback((value: ContributorResponse | null) => {
        if (value) {
            setFormData(prev => ({
                ...prev,
                contributors: [{ id: value.id, role: 'MAIN_ARTIST' }], // Заменяем массив одним элементом
            }))
        }
    }, [])

    const handleGenreChange = useCallback((value: ReleaseGenre | null) => {
        if (value) {
            setFormData(prev => ({
                ...prev,
                genres: [{ genre: value.name, is_main: true }], // Заменяем массив одним элементом
            }))
        }
    }, [])

    const fetchGenres = (): Promise<ReleaseGenresResponse> => releaseStore.getGenres()
    const fetchContributors = (): Promise<GetContributorsResponse> => contributorStore.getContributors()

    return (
        <StyledPaper>
            <Grid container padding={2} columnSpacing={1} justifyContent={'center'}>
                <Grid item xs={4.5}>
                    <Typography noWrap variant="subtitle1">ТИП РЕЛИЗА</Typography>
                    <ReleasePaperStyled sx={{ height: '91%' }}>
                        <SelectRelease>
                            {selectReleaseItems.map((item, index) => (
                                <SelectReleaseItem
                                    key={index}
                                    value={item.type}  // Убедитесь, что value используется для сопоставления типа
                                    onClick={() => handleTypeClick(index)}
                                    selected={formData.type === item.type}  // Проверяем, совпадает ли текущий тип с типом элемента
                                    disabled={index === 3 || index === 4 || index === 5}
                                >
                                    <SelectReleaseTypography>{item.title}<br/>{item.price}</SelectReleaseTypography>
                                </SelectReleaseItem>
                            ))}
                        </SelectRelease>

                    </ReleasePaperStyled>
                </Grid>
                <Grid item xs={7.5}>
                    <Typography variant="subtitle1">ОБЛОЖКА</Typography>
                    <Dropzone setCover={(cover) => handleChangeCover('cover', cover)}/>
                </Grid>
                <Grid item container direction="row" justifyContent="space-between" alignItems="center" xs={12}>
                    <Typography variant="subtitle1">ИНФОРМАЦИЯ О РЕЛИЗЕ</Typography>
                    <Stack direction="row" alignItems="inherit">
                        <Typography variant="body1" color="grey">Подсказки</Typography>
                        <Checkbox
                            onChange={() => setHelpVisibility(!helpVisibility)}
                            defaultChecked
                        />
                    </Stack>
                </Grid>
                <GridJCC>
                    <Stack direction="column" width="95%">
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                            <StyledMiniTitle>Название</StyledMiniTitle>
                            {helpVisibility && <InfoPopup value={inputValue}/>}
                        </Stack>
                        <TFMargin
                            placeholder="Какое будет название вашего релиза?"
                            onChange={handleInputChange('title')}
                            variant="outlined"
                        />
                    </Stack>
                </GridJCC>
                <GridJCC>
                    <Stack direction="column" width="95%">
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                            <StyledMiniTitle>Исполнитель</StyledMiniTitle>
                            {helpVisibility && <InfoPopup value={inputValue}/>}
                        </Stack>
                        <SelectField<ContributorResponse>
                            fetchFunction={fetchContributors}
                            getOptionLabel={(option) => option.username}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            handleInputChange={handleContributorChange}
                        />
                        <Button variant="text" disabled>
                            <Typography color="grey" textTransform="none" variant="body2">Добавить feat. исполнителя,
                                версию релиза</Typography>
                        </Button>
                    </Stack>
                </GridJCC>
                <GridJCC>
                    <Stack direction="column" width="95%">
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                            <StyledMiniTitle>Лейбл</StyledMiniTitle>
                            {helpVisibility && <InfoPopup value={inputValue}/>}
                        </Stack>
                        <TFMargin
                            placeholder="Voque"

                            fullWidth
                            onChange={handleInputChange('label')}
                        />
                    </Stack>
                </GridJCC>
                <GridJCC>
                    <Stack direction="column" width="95%">
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                            <StyledMiniTitle>Дата публикации</StyledMiniTitle>
                            {helpVisibility && <InfoPopup value={inputValue}/>}
                        </Stack>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                minDate={allowedMinDate}
                                onChange={handleDateChange('release_date')}
                            />
                        </LocalizationProvider>
                    </Stack>
                </GridJCC>
                <GridJCC>
                    <Stack direction="column" width="95%">
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                            <StyledMiniTitle>Основной жанр</StyledMiniTitle>
                            {helpVisibility && <InfoPopup value={inputValue}/>}
                        </Stack>
                        <SelectField<ReleaseGenre>
                            fetchFunction={fetchGenres}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            handleInputChange={handleGenreChange}
                        />
                        <Button variant="text" disabled>
                            <Typography color="grey" textTransform="none" variant="body2">Добавить дополнительный
                                жанр</Typography>
                        </Button>
                    </Stack>
                </GridJCC>
                <GridJCC>
                    <Stack direction="column" width="95%">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <StyledMiniTitle>UPC</StyledMiniTitle>
                            {helpVisibility && <InfoPopup value={inputValue}/>}
                        </Stack>
                        <TFMargin disabled
                                  onChange={handleInputChange('upc')}
                        />
                    </Stack>
                </GridJCC>
                <Grid item xs={12}>
                    <TrackParams/>
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*    <Typography variant="subtitle1">ПРОМО-ПИТЧИНГ</Typography>*/}
                {/*    <p>1234567890</p>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <Typography variant="subtitle1">ПЛАТФОРМЫ И СТРАНЫ</Typography>*/}
                {/*    <p>1234567890</p>*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <Button variant="outlined"
                            onClick={submitRelease}
                            fullWidth
                            sx={{ borderRadius: '12px' }}>
                        <Typography textTransform="none" fontFamily="Geologica400" variant="h3">Перейти к отправке
                            релиза</Typography>
                    </Button>
                </Grid>
            </Grid>
        </StyledPaper>
    )
})

