import React from 'react'
import { ReleasesMenuItemStyled, ReleasesMenuListStyled, ReleasesStack } from './AddReleasesMenu.styled'
import { Link, Typography } from '@mui/material'
import { menuItems } from './items'


export const AddReleasesMenu: React.FC = () => {
    return (<ReleasesStack direction="column" spacing={1}>
            <Link underline="none" href={'/'}>
                <Typography variant="h3" fontFamily="Geologica500">На главную</Typography></Link>
            <ReleasesMenuListStyled>
                {menuItems.map((item, index) => (
                    <ReleasesMenuItemStyled key={index}>
                        <Typography variant="subtitle1" fontFamily="Geologica300" noWrap>{item.title}</Typography>
                    </ReleasesMenuItemStyled>
                ))}
            </ReleasesMenuListStyled>
        </ReleasesStack>

    )
}