import React from 'react'
import { HeaderLogoText, HeaderP, HeaderStyled, StyledLink } from './Header.styled'
import { Avatar, Grid, Stack } from '@mui/material'
import { Container } from '../Container/Container'
import { ava, Logo } from '../index'
import { AddReleaseDialog } from './AddReleaseDialog'
import { useNavigate } from 'react-router-dom'
import { Logout, Notifications } from '@mui/icons-material'
import authStore from '../../store/AuthStore'
import { observer } from 'mobx-react-lite'


export const Header: React.FC = observer(() => {
    const currentUrl = window.location.href
    //const isHomePage = currentUrl.includes('/releases')
    const isHomePage = currentUrl.includes('/add')
    const navigate = useNavigate()

    const handleLogout = async (event: React.FormEvent) => {
        event.preventDefault()
        const response = await authStore.logout()
        if (response.redirectTo) {
            navigate(response.redirectTo)
        } else if (response.error) {
            console.error(response.error.message)
        }

    }

    return (<header>
        <Container>
            <HeaderStyled className={'stars'}>
                <Grid borderRadius="inherit" className="twinkling" height="inherit" container direction="row"
                      alignItems="center" padding={1}>
                    <Grid item xs={2}
                    >
                        <StyledLink to={'/'}>
                            <Logo className="svg-icon"/>

                            <HeaderLogoText className="hideWhen980" noWrap variant="h2">Voque Stars</HeaderLogoText>
                        </StyledLink>
                    </Grid>
                    <Grid item xs={7} xl={8}>
                        {!isHomePage &&
                            <AddReleaseDialog/>}
                    </Grid>
                    <Grid item xs={3} xl={2}>
                        <Stack height="100%" justifyContent="space-evenly" alignItems="center" direction="row">
                            <HeaderP variant="h3">1,172,928 руб</HeaderP>
                            <Notifications color="secondary" fontSize="large"/>
                            <StyledLink to={'/profile'}>
                                <Avatar src={ava} alt="Слава"/>
                            </StyledLink>

                            <Logout cursor="pointer" onClick={handleLogout} color="secondary" fontSize="large"/>


                        </Stack>
                    </Grid>
                </Grid>
            </HeaderStyled>
        </Container>
    </header>)
})

///old header, пусть пока будет
/*
<Container>
    <HeaderStyled container direction="row"
                  spacing={1}>
        <Grid item style={{
            display: 'inherit',
            flexDirection: 'inherit',
            alignItems: 'inherit',
            justifyContent: 'center',
            width: '15%',
        }}>
            <Logo/>
            <HeaderH1>Voque Stars</HeaderH1>
        </Grid>
        <Grid item style={{
            display: 'inherit',
            justifyContent: 'space-between',
            flexDirection: 'inherit',
            width: '85%',
            alignItems: 'inherit',
        }}>
            <AddReleaseBut variant="outlined">
                <Plus style={{ paddingRight: '2%' }}/>
                <HeaderP>Создать релиз</HeaderP>
            </AddReleaseBut>
            <div style={{
                display: 'inherit',
                flexDirection: 'inherit',
                width: '30%',
                justifyContent: 'end',
                alignItems: 'inherit',
            }}>
                <HeaderP>1,172,928 руб</HeaderP>
                <Notification/>
                <Avatar src={ava} alt=""/>
            </div>
        </Grid>
    </HeaderStyled>
</Container>*/