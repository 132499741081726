import { makeAutoObservable } from 'mobx'
import { TOKEN_KEY } from './AuthStore'
import { GetContributorsResponse } from './ContributorStore'
import { Role } from './ReleaseStore'


export interface TrackObject {
    id: string,
    contributors: [
        {
            id: string,
            role: Role
        }
    ],
    isrc: string,
    title: string,
    audio_file: string,
    lyrics?: string,
    lyrics_language?: string,
    genres: [],
    karaoke?: string,
    adult?: true,
    instrumental?: true,
    record_year?: 0,
    share?: [
        {
            email: string,
            royalty_percent: 0,
            roles: [
                {
                    role: string
                }
            ],
            confirmed: false
        }
    ]
}


export interface GetTracksResponse extends Array<TrackObject> {
}


const API_URL = 'http://83.222.10.61:1234/api/v1'


class TrackStore {

    constructor() {
        makeAutoObservable(this)
        // this.apiUrl = process.env.API_URL || 'http://localhost:8000'
    }

    async getTracks(releaseId: string): Promise<GetTracksResponse> {
        const token = localStorage.getItem(TOKEN_KEY)
        try {
            const response = await fetch(API_URL + `/releases/${releaseId}/tracks/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
            const data = await response.json()
            return data

        } catch (error) {
            console.error(error)
            throw error
        } finally {
            console.log('Попытка получить треки закончена')
        }
    }
}


const trackStore = new TrackStore()
export default trackStore