import { Document, Page, pdfjs } from 'react-pdf'
import vsld from '../../../assets/vsld.pdf'
import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { StyledPaper } from '../../Releases/Releases.styled'
import 'react-pdf/dist/Page/AnnotationLayer.css'
// Ensure that the PDF.js worker is loaded correctly
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString()

export const RenderDocument: React.FC = () => {
    const [numPages, setNumPages] = useState<number>(0)
    const [pageNumber, setPageNumber] = useState<number>(1)

    // Handler for when the document is successfully loaded
    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages)  // Correctly set the number of pages
    }

    // Navigate to the previous page
    const goToPreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1)
        }
    }

    // Navigate to the next page
    const goToNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1)
        }
    }

    return (
        <StyledPaper>


            {/* PDF Document */}
            <Grid container padding={1.2}>
                <Grid item xs={2}>
                    {/* Pagination Controls */}
                    <Box
                        display="flex"
                        flexDirection="column-reverse"
                        justifyContent="center"
                        alignItems="center"
                        mt={2}
                    >
                        <Button
                            variant="contained"
                            onClick={goToPreviousPage}
                            disabled={pageNumber <= 1}
                        >
                            Назад
                        </Button>

                        <Typography>
                            Страница {pageNumber} из {numPages}
                        </Typography>

                        <Button
                            variant="contained"
                            onClick={goToNextPage}
                            disabled={pageNumber >= numPages}
                        >
                            Далее
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Document
                        file={vsld}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page renderAnnotationLayer={false} pageNumber={pageNumber} renderTextLayer={false}/>
                    </Document>
                </Grid>

            </Grid>


        </StyledPaper>)
}
