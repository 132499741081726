import { Stack, Typography } from '@mui/material'
import { Close, HelpOutline } from '@mui/icons-material'
import React, { useState } from 'react'
import { IconButtonStyled, PopoverStyled } from './InfoPopup.styled'


interface InfoPopupProps {
    value: React.ReactNode | React.ReactNode[]
}


export const InfoPopup: React.FC<InfoPopupProps> = ({ value }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    const handleInfoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleInfoClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    return (
        <React.Fragment>
            <IconButtonStyled
                onClick={handleInfoClick}
            >
                <HelpOutline color="info"
                    // visibility={checked}
                />
            </IconButtonStyled>
            <PopoverStyled
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleInfoClose}

            >
                <Stack direction="column" alignItems={'space-between'} justifyContent={'space-between'}
                       height="100%">
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="h6" fontFamily={'Geologica600'} textTransform={'uppercase'}>Название
                            параметра</Typography>
                        <IconButtonStyled onClick={handleInfoClose}>
                            <Close fontSize="large"/>
                        </IconButtonStyled>
                    </Stack>
                    {value}
                </Stack>

            </PopoverStyled>
        </React.Fragment>)
}