import React from 'react'
import { Route, Navigate } from 'react-router-dom'
import authStore from '../store/AuthStore'


interface ProtectedRouteProps {
    element: React.ComponentType<any>;

}


const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element: Element }) => {
    const isAuthenticated = authStore.isAuthenticated

    if (!isAuthenticated) {
        return <Navigate to="/signIn"/>
    }

    return <Element/>
}

export default ProtectedRoute
