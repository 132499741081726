import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { GlobalStyles } from './styles/global.styled'
import { theme } from './styles/theme'
import { BrowserRouter as Router } from 'react-router-dom'
import { Router as AppRoutes } from './routes/Router'


function App() {
    
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <GlobalStyles/>
            <Router>
                <AppRoutes/>
            </Router>

        </ThemeProvider>
    )
}

export default App
