import { observer } from 'mobx-react-lite'
import React from 'react'
import { Stack, Typography } from '@mui/material'
import { star } from './index'
import { StyledPaper } from '../Releases/Releases.styled'
import { textItems } from './items'
import { StyledBoxPlug, StyledPaperPlug, StyledPlugBut } from './Plug.styled'


export const Plug: React.FC = observer(() => {
    const starImg = star
    return (
        <Stack spacing={5} direction={'column'} justifyContent={'space-between'} textAlign={'center'}
               alignItems={'center'}
               height={'100%'}>
            <StyledBoxPlug component="img" alt={'Star'} src={starImg}/>
            <StyledPaper>
                <Typography variant={'h1'} marginBottom={'1%'}>{textItems[0].title}</Typography>
                <Typography variant={'body1'}>{textItems[1].title}</Typography>
            </StyledPaper>
            <StyledPaperPlug>
                <Stack spacing={1} direction={'column'} textAlign={'center'} justifyContent={'center'}
                       alignItems={'center'} padding={2}>
                    <Typography variant={'body2'} color={'#999999'}>{textItems[2].title}</Typography>
                    <StyledPlugBut variant="contained">
                        <Typography variant={'body1'} color={'#352CE8'}>
                            {textItems[3].title}
                        </Typography></StyledPlugBut>
                </Stack>

            </StyledPaperPlug>


        </Stack>)
})
