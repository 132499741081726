import React, { FC, ReactNode } from 'react'
import { StyledContainer } from './Container.styled'


interface ContainerProps {
    children: ReactNode
}


export const Container: FC<ContainerProps> = ({ children }) => (
    <StyledContainer>
        {children}
    </StyledContainer>
)