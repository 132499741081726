import React, { useEffect, useRef, useState } from 'react'
import {
    LinearProgressStyled,
    ReleaseImgStyled,
    ReleasePaperStyled,
    WhiteCardReleaseStyled,
} from './ReleaseCard.styled'
import { Grid, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReleaseKey } from '../Releases'
import releaseStore, { ReleaseResponseItems } from '../../../store/ReleaseStore'
import { StyledPaper } from '../Releases.styled'
import { TracksComponent } from './Tracks/Tracks'
import { BorderColor, Close, Delete } from '@mui/icons-material'
import { IconButtonStyled } from '../../AddRelease/AddReleasesCard/InfoPopup/InfoPopup.styled'


interface ReleaseCardProps {
    releases: ReleaseResponseItems | null;
    initialSelectedId?: ReleaseKey | null;
    onDeleteRelease: (id: ReleaseKey) => void;
    // onEditRelease: (id: ReleaseKey) => void;
    onCloseRelease: () => void;
}


export const statusLabels = {
    DRAFT: 'Черновик',
    ON_MODERATION: 'Модерация',
    CONFIRMED: 'Подтвержден',
    UPLOADED: 'Выпущен',
    DELETED: 'Удален',
}

export const ReleaseCard: React.FC<ReleaseCardProps> = observer(({
                                                                     releases,
                                                                     initialSelectedId,
                                                                     onDeleteRelease,
                                                                     onCloseRelease,
                                                                     // onEditRelease,
                                                                 }: ReleaseCardProps) => {
    const [selectedHearValue, setSelectedHearValue] = useState('10')
    const [selectedId, setSelectedId] = useState<ReleaseKey | null>(null) // Состояние для selectedId

    const numOfHear = {
        month: 1337,
        year: 13337,
        week: 137,
    }

    const imgRef = useRef<HTMLImageElement>(null)

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedHearValue(event.target.value)
    }

    if (!releases) {
        return null
    }

    useEffect(() => {
        if (initialSelectedId) {
            setSelectedId(initialSelectedId)
        }
    }, [initialSelectedId])

    // Поиск выбранного релиза
    const selectedRelease = releases.find(release => release.id === selectedId)
    if (!selectedRelease) {
        return null
    }

    const renderText = () => {
        switch (selectedHearValue) {
            case '10':
                return numOfHear.month
            case '20':
                return numOfHear.year
            case '30':
                return numOfHear.week
            default:
                return numOfHear.month
        }
    }

    return (
        <React.Fragment>
            <StyledPaper>
                <Stack direction={'column'}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} paddingLeft={1}
                           paddingBottom={1}>
                        <Typography variant={'body1'}
                            // color={'#0E9C13'}
                        >
                            {statusLabels[selectedRelease.status] || 'Неизвестный статус'}
                        </Typography>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            {selectedRelease.status === 'DRAFT' ?
                                <IconButtonStyled onClick={() => onDeleteRelease(selectedRelease.id)}>
                                    <Delete fontSize={'large'}/>
                                </IconButtonStyled>
                                : null}

                            <IconButtonStyled
                                // onClick={onEditRelease}
                                disabled
                            >
                                <BorderColor fontSize={'large'}/></IconButtonStyled>
                            <IconButtonStyled onClick={onCloseRelease}>
                                <Close fontSize={'large'}/></IconButtonStyled>
                        </Stack>
                        {/*{selectedRelease.status === 'DRAFT' ? (*/}
                        {/*    <Button onClick={handleDeleteRelease}>Удалить релиз</Button>*/}
                        {/*) : null}*/}
                    </Stack>
                    <ReleasePaperStyled>
                        <Grid container direction="row" columnSpacing={1} padding={1} alignItems="flex-start">
                            <Grid item xs={4}>
                                <ReleaseImgStyled component="img" src={selectedRelease.cover} ref={imgRef}/>
                            </Grid>

                            <Grid item container xs={8} spacing={1} alignItems="space-between">
                                <Grid item xs={7}>
                                    <WhiteCardReleaseStyled sx={{ height: '100%' }}>
                                        <Stack
                                            width="100%"
                                            height="100%"
                                            direction="column"
                                            padding={1}
                                            alignItems="center"
                                            justifyContent="space-around"
                                        >
                                            <Typography
                                                width="inherit"
                                                noWrap
                                                alignSelf="start"
                                                variant="h4"
                                                color="#999999"
                                            >
                                                ПРОСЛУШИВАНИЯ
                                            </Typography>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedHearValue}
                                                onChange={handleChange}
                                                disableUnderline
                                                variant="standard"
                                                sx={{ textAlign: 'center', maxWidth: '70%' }}
                                            >
                                                <MenuItem value={'10'}>
                                                    <Typography variant="body1">За прошлый месяц</Typography>
                                                </MenuItem>
                                                <MenuItem value={'20'}>
                                                    <Typography variant="body1">За прошлый год</Typography>
                                                </MenuItem>
                                                <MenuItem value={'30'}>
                                                    <Typography variant="body1">За прошлую неделю</Typography>
                                                </MenuItem>
                                            </Select>
                                            <Typography
                                                width="100%"
                                                textAlign="center"
                                                fontFamily="Geologica700"
                                                variant="h2"
                                            >
                                                {renderText()}
                                            </Typography>
                                        </Stack>
                                    </WhiteCardReleaseStyled>
                                </Grid>

                                <Grid item xs={5}>
                                    <WhiteCardReleaseStyled sx={{ height: '100%' }}>
                                        <Stack
                                            width="100%"
                                            direction="column"
                                            padding={1}
                                            height="inherit"
                                            justifyContent="space-around"
                                        >
                                            <Typography
                                                width="inherit"
                                                variant="h4"
                                                color="#999999"
                                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                            >
                                                БЕСПЛАТНЫЙ МЕСЯЦ ДИСТРИБУЦИИ
                                            </Typography>
                                            <LinearProgressStyled variant="determinate" value={66}/>
                                        </Stack>
                                    </WhiteCardReleaseStyled>
                                </Grid>


                                <Grid item xs={12}>
                                    <WhiteCardReleaseStyled>
                                        <Stack direction="column" spacing={1} padding={1}
                                               justifyContent="space-around">
                                            <Typography variant="h4">ОПИСАНИЕ РЕЛИЗА</Typography>
                                            <Typography paragraph variant="body2" height="100%">
                                                {selectedRelease.promo_description ? selectedRelease.promo_description : 'Описание отсуствует'} {/* Выводим описание */}
                                            </Typography>
                                        </Stack>
                                    </WhiteCardReleaseStyled>
                                </Grid>

                                {/*<WhiteCardReleaseStyled>*/}
                                {/*    <Stack*/}
                                {/*        direction="column"*/}
                                {/*        spacing={1}*/}
                                {/*        padding={1}*/}
                                {/*        justifyContent="space-around"*/}
                                {/*    >*/}
                                {/*        <Typography variant="h4">ОПИСАНИЕ РЕЛИЗА</Typography>*/}
                                {/*        <Typography paragraph variant="body2" height="100%">*/}
                                {/*            {selectedRelease.title}*/}
                                {/*        </Typography>*/}
                                {/*    </Stack>*/}
                                {/*</WhiteCardReleaseStyled>*/}

                            </Grid>
                        </Grid>

                        <Grid container direction="row" rowSpacing={1} padding={1} paddingTop={0}
                              alignItems="flex-start">
                            <Grid item xs={12}>
                                <WhiteCardReleaseStyled>
                                    <Typography padding={1} width="100%" variant="h4">
                                        ДЕТАЛИ
                                    </Typography>
                                </WhiteCardReleaseStyled>
                            </Grid>
                            <Grid item xs={12}>
                                <TracksComponent releaseId={selectedId}/>

                            </Grid>
                        </Grid>
                    </ReleasePaperStyled>
                </Stack>
            </StyledPaper>
        </React.Fragment>
    )
})
