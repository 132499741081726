import { Button, Stack, styled, TextField } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'


export const TFMargin = styled(TextField)(({ theme }) => ({
    // marginTop: '0.5rem',

    '& .MuiInputBase-input': {
        padding: '1%',
        // Applying padding to the input element
    },
    '& .MuiInputBase-root': {
        borderRadius: '8px',

    },
}))

TFMargin.defaultProps = {
    fullWidth: true,

}
export const StyledAccordion = styled(Accordion)(({ theme }) => ({
    '&:before': {
        display: 'none',
    },
    boxShadow: 'none',
    borderRadius: '12px',
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    // '& .MuiAccordionSummary-content': {
    //     alignItems: 'center',
    //     width: '50%',
    //     justifyContent: 'space-between',
    //
    // },
    padding: '0',
}))

export const Stack100 = styled(Stack)(({ theme }) => ({
    flexDirection: 'column',
    width: '100%',
}))

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: '0 2% 2% 2%',
}))
export const StyledAddTrackButton = styled(Button)(({ theme }) => ({
    borderRadius: '32px',
    marginBottom: '2%',

}))
